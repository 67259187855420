import React, { useState, useEffect, useRef } from 'react';

const Terminal = () => {
  const [input, setInput] = useState('');
  const introText = 'WELCOME TO JAMIEMAISON.COM \nTYPE /HELP FOR LIST OF COMMANDS';
  const [output, setOutput] = useState([]);
  const [displayedOutput, setDisplayedOutput] = useState([introText]);
  const inputRef = useRef(null);

  const commands = {
    "/ABOUT": 'JAMIE MAISON IS A BRITISH SENIOR ENGINEERING MANAGER WHO SPECIALISES IN VIDEO PLAYER TECHNOLOGY AND THE ROKU PLATFORM FOR SKY & NBCUNIVERSAL. \n\nHE IS ALSO TERRIBLE AT WRITING DESCRIPTIONS ABOUT HIMSELF.',
    "ABOUT": 'JAMIE MAISON IS A BRITISH SENIOR ENGINEERING MANAGER WHO SPECIALISES IN VIDEO PLAYER TECHNOLOGY AND THE ROKU PLATFORM FOR SKY & NBCUNIVERSAL. \n\nHE IS ALSO TERRIBLE AT WRITING DESCRIPTIONS ABOUT HIMSELF.',
    "/CONTACT": 'CONTACT JAMIE AT JAMIE@JAMIEMAISON.COM',
    "CONTACT": 'CONTACT JAMIE AT JAMIE@JAMIEMAISON.COM',
    "/HELP": 'COMMANDS: \n/ABOUT \n/CONTACT \n/HELP \n/SECRET \n/WORK',
    "HELP": 'COMMANDS: \n/ABOUT \n/CONTACT \n/HELP \n/SECRET \n/WORK',
    "/SECRET": 'WHAT IS THE BEST GAME SERIES OF ALL TIME?',
    "SECRET": 'WHAT IS THE BEST GAME SERIES OF ALL TIME?',
    "/WORK": 'I WORK ON COOL STUFF FOR SKY & NBCUNIVERSAL: \n\nlinkedin.com/in/jamiemaison',
    "WORK": 'I WORK ON COOL STUFF FOR SKY & NBCUNIVERSAL: \n\nlinkedin.com/in/jamiemaison',

    "HELLO": 'HELLO! NICE TO MEET YOU. IM JAMIE.',
    "GOODBYE": 'GOING SO SOON? DONT FORGET TO CLOSE YOUR TAB ON YOUR WAY OUT!',
    "EXIT": 'GOING SO SOON? DONT FORGET TO CLOSE YOUR TAB ON YOUR WAY OUT!',
    "JAMIE": 'IS A HANDSOME FELLOW.',
    "MAISON": 'FRENCH FOR HOUSE, DONT YOU KNOW?',
    "JAMIEMAISON": 'STOP POKING ME!',
    "THANK YOU": 'I DONT KNOW WHAT YOURE THANKING ME FOR, BUT YOURE WELCOME.',
    "ANSWER": 'WHAT DO YOU THINK I AM, CHATGPT?',

    "MONKEY ISLAND": 'WOW, THATS CORRECT! YOU FIGHT LIKE A DAIRY FARMER!',
    "HOW APPROPRIATE. YOU FIGHT LIKE A COW!": 'A PERSON OF TASTE I SEE! WE SHOULD BE FRIENDS.',
    "HOW APPROPRIATE. YOU FIGHT LIKE A COW": 'A PERSON OF TASTE I SEE! WE SHOULD BE FRIENDS.',
    "HOW APPROPRIATE YOU FIGHT LIKE A COW": 'A PERSON OF TASTE I SEE! WE SHOULD BE FRIENDS.',
    "HOW APPROPRIATE YOU FIGHT LIKE A COW!": 'A PERSON OF TASTE I SEE! WE SHOULD BE FRIENDS.',
    "YOU FIGHT LIKE A COW!": 'SO CLOSE',
    "YOU FIGHT LIKE A COW": 'SO CLOSE',
  };

  useEffect(() => {
    if (output.length > 0) {
      const lastLine = output[output.length - 1];
      typeEffect(lastLine, 0);
    }
  }, [output]);

  const typeEffect = (line, index) => {
    if (index < line.length) {
      setDisplayedOutput((prev) => [...prev, line[index].toUpperCase()]);
      setTimeout(() => typeEffect(line, index + 1), 50);
    } else {
      setDisplayedOutput((prev) => [...prev, '\n']);
    }
  };

  const handleInput = (event) => {
    if (event.key === 'Enter') {
      const response = commands[input.toUpperCase()] || 'COMMAND NOT RECOGNISED';
      setOutput([`> ${input.toUpperCase()}`, response.toUpperCase()]);
      setInput('');
      setDisplayedOutput([]);
    }
  };

  const focusInput = () => {
    inputRef.current.focus();
  };

  return (
    <div className="terminal" onClick={focusInput}>
      <input
        ref={inputRef}
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value.toUpperCase())}
        onKeyDown={handleInput}
        autoFocus
        style={{ textTransform: 'uppercase' }}
      />
      <pre>{displayedOutput}</pre>
    </div>
  );
};

export default Terminal;
